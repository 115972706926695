<template>
  <div>
    <vx-card>
      <vs-button
        class="flex ml-auto"
        @click="$router.push({ name: 'partner-users-create' })"
        >Add User</vs-button
      >
      <vs-table :max-items="descriptionItems[0]" pagination :data="users">
        <template slot="thead">
          <vs-th>
            Name
          </vs-th>
          <vs-th>
            Email
          </vs-th>
          <vs-th>
            Phone Number
          </vs-th>
          <vs-th>
            Action
          </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(user, indextr) in data">
            <vs-td :data="user.title">
              {{ user.fullName }}
            </vs-td>
            <vs-td :data="user.reference">
              {{ user.email }}
            </vs-td>
            <vs-td :data="user.companyName">
              {{ user.phoneNumber }}
            </vs-td>

            <vs-td :data="user._id">
              <feather-icon
                icon="EditIcon"
                svgClasses="h-4 w-4"
                class="ml-1"
                @click="handleEdit(user._id)"
              />
              <feather-icon
                icon="Trash2Icon"
                svgClasses="h-4 w-4"
                class="ml-1 pl-2"
                @click="confirmDeleteAlert(user._id)"
              />
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";

import "sweetalert2/src/sweetalert2.scss";
export default {
  data() {
    return {
      descriptionItems: [10, 25, 40],
      users: []
    };
  },
  methods: {
    ...mapActions("partner", ["fetchStaffByPartnerId", "deleteStaff"]),
    async getUsers(partnerId) {
      await this.fetchStaffByPartnerId(partnerId)
        .then(result => {
          this.users = result.data.data;
        })
        .catch(err => {});
    },
    handleEdit(id) {
      this.$router.push({
        name: "partner-users-edit",
        params: { id: id }
      });
    },
    confirmDeleteAlert(id){
      let self = this;
      Swal.fire({
        title: "Are you sure?",
        text: "The user will be permanently deleted.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it",
        cancelButtonText: "No"
      }).then(result => {
        if (result.value) {
          self.handleDelete(id);
        }
      });
    },
    async handleDelete(id) {
      this.$vs.loading();
      await this.deleteStaff(id)
        .then(result => {
          this.$vs.loading.close();
          this.showMessage(
            "Successful",
            "User has been deleted successfully.",
            "success",
            "icon-check-circle"
          );
          this.getUsers(this.partnerId);
        })
        .catch(err =>
        {
          this.$vs.loading.close();
          {}
        });
    },
    showMessage(
      title = "Successful",
      message = "Task completed",
      color = "success",
      icon = "icon-check-circle"
    ) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,

      });
    }
  },
  mounted() {
    this.getUsers(this.partnerId);
  },
  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },
    partnerId() {
      return this.user._id;
    }
  }
};
</script>
